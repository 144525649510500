import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import CardInfoComponent from '../../../components/Cards/cardInfo.component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';

interface Props {
  // Define your component props here
  user?: Partial<IUser>;

}

const SeguimientoScreen: React.FC<Props> = ({user}) => {
  const steps = [
    { title: "Completa tu perfil", description: "Registrada", status: "status--success", completed: true, color: "green350" },
    { title: "Te presentamos a tu acompañante", description: "En proceso", status: "status--success", completed: true, color: "green350" },
    { title: "Llamada telefónica", description: "Pendiente", status: "status--success", completed: true, color: "green350" },
    { title: "Accede a la ley 21.030", description: "Pendiente", status: "status--success", completed: true, color: "green350" },
    { title: "Seguimos en contacto", description: "En proceso", status: "status--process", completed: false, color: "pink400" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];
  return (
    <>
      <HeaderShared user={user}/>
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
              
            </div>
            <div className="module-bottom40 row">
              <div className="col-12 text-center">
                <h1 className="text-52">Sigue atenta a las señales de tu cuerpo</h1>
               <div className="col-lg-9 col-12 mx-auto">
                  <p className="text-24 mb-0">Lee cuidadosamente la información que te brindamos</p>
                  <p className="text-24">Para cualquier duda no pienses en preguntarle a tu acompañante, ella te brindará todo el apoyo necesario</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12 mb-3">
                <CardInfoComponent
                  title="Si presentas los siguientes síntomas:"
                  alert={<>Comunícate con tu acompañante <strong> lo más pronto posible</strong></>}
                  items={[
                    { image: "/assets/images/icons/covid.svg", text: "Fiebre" },
                    { image: "/assets/images/icons/sync.svg", text: "Mal Olor" },
                    { image: "/assets/images/icons/intestinal.svg", text: "Dolor" },
                  ]}
                />
              </div>
              <div className="col-lg-4 col-12 mb-3">
                <CardInfoComponent
                  title="No olvides que"
                  type="type--1"
                  items={[
                    { image: "/assets/images/icons/calendar.svg", text: <><strong>Los orgasmos clitorianos</strong> ayudan a expulsar restos. Se recomienda <strong>entre dos y tres diarios</strong> durante al menos <strong>7 días corridos</strong></> },
                    { image: "/assets/images/icons/fish.svg", text: "Consume alimentos ricos en hierro y abundante hidratación " },
                    { image: "/assets/images/icons/food.svg", text: "Puedes beber infusiones de artemisa o ajenjo para facilitar la expulsión" },
                  ]}
                />
              </div>
              <div className="col-lg-4 col-12 mb-3">
              <CardInfoComponent
                  title={<>Durante los próximos 40 días <strong>NO</strong>:</>}
                  type="type--2"
                  items={[
                    { image: "/assets/images/icons/step-item.svg", text: "Usar copa menstrual o tampones" },
                    { image: "/assets/images/icons/step.svg", text: "Tener relaciones sexuales penetrativas " },
                    { image: "/assets/images/icons/step-arrow.svg", text: "Introducir objetos en la vagina" },
                    { image: "/assets/images/icons/pool.svg", text: "Sumergirse en el mar, lagos o piscinas" },
                  ]}
                />
              </div>
              
            </div>
            <div className="module-top40 row">
              <div className="col-12 text-center">
                <p className="text-green900">¿Tienes alguna duda con esto?</p>
              </div>
              <div className="col-12 text-center mb-3">
                <a href="/chat-acompanante" className="btn btn--type1">Contactar acompañante</a>
              </div>
              <div className="col-12 text-center">
                <p className="text-wine400 text-20">Si presentas <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>Hemorragia</Tooltip>
              }
            >
             <strong>hemorragia</strong>
            </OverlayTrigger> dirígete inmediatamente a urgencia médica.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SeguimientoScreen