import React, { useEffect, useState } from 'react';
import ProcessStatusComponent from '../../../../components/ProcessStatus/processStatus.component';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import ChatComponent from '../../../../components/Chat/chat.component';
import { IUser } from '../../../../types/userInterface';
import { MessageBoxType } from 'react-chat-elements';
import { fetchChatMessages } from '../../../../api/chatApi';
import HeaderShared from '../../../../shared/header.shared';
import FooterShared from '../../../../shared/footer.shared';

interface ChatAcompananteScreenProps {
  user: Partial<IUser>;
  token: string;
}

const ChatAcompananteScreen: React.FC<ChatAcompananteScreenProps> = ({
  user,
  token,
}) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<MessageBoxType[]>([]);
  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Llamada telefónica',
      description: 'Pendiente',
      status: 'status--process',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status--process',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Seguimos en contacto',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
  ];

  const fetchOldMessages = async (roomId: string, token: string) => {
    try {
      setLoading(true);
      const response = await fetchChatMessages(roomId, token);
      const userId = user._id;

      const messages = response.docs.reverse().map((doc) => {
        const message: MessageBoxType = {
          id: doc._id,
          position: doc.sender === userId ? 'right' : 'left',
          type: 'text',
          text: doc.message,
          date: new Date(doc.createdAt),
          avatar:
            doc.sender === userId
              ? '/assets/images/icons/user-small.svg'
              : '/assets/images/icons/user-pic-2.svg',
          title: doc.sender === userId ? 'Tu' : doc.senderName,
          focus: false,
          forwarded: false,
          replyButton: false,
          removeButton: false,
          titleColor: '#000000',
          status: doc.sender === userId ? 'sent' : 'received',
          notch: true,
          retracted: false,
        };
        return message;
      });

      setMessages([...messages]);
    } catch (error) {
      console.error('Error fetching old messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const setNewMessage = (msg: MessageBoxType) => {
    console.log('Received message:', msg);
    setMessages((prevMessages) => [...prevMessages, msg]);
    // scroll to the bottom of the chat element with class .rce-mlist
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        console.log('Chat container found:', chatContainer.scrollHeight);
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  useEffect(() => {
    if (user.assignedTo?.chatRoomId && token) {
      fetchOldMessages(user.assignedTo?.chatRoomId, token);
    }
  }, [user]);

  return (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={1} />
              </div>
            </div>
            <div className="module-bottom40 row">
              {loading ? (
                <div className="text-center col-12">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  <ChatComponent
                    user={user}
                    roomId={user.assignedTo?.chatRoomId || ''}
                    token={token}
                    messages={messages}
                    setNewMessage={setNewMessage}
                  />
                </div>
              )}
            </div>
            <div className="module-bottom-40 row">
              <div className="text-center col-12">
                <a href="/acompanamiento" className="btn btn--type1">
                  Regresar al inicio
                </a>
                <article className="mx-auto mt-4 col-lg-5 col-12">
                  <p>
                    Siéntete en confianza. Recuerda que tu historial de
                    conversación es privado, sólo Las Amigas y tú sabemos lo que
                    se está compartiendo.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ChatAcompananteScreen;
