import React, {useEffect, useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import SocioDemographicsComponent from '../../../components/Auth/SocioDemographics/socioDemographics.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import { IUser } from '../../../types/userInterface';


interface SocioDemographicsScreenProps {
  user?: Partial<IUser>;
}

const SocioDemographicsScreen: React.FC<SocioDemographicsScreenProps> = ({
  user,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentStep] = useState(3);
  const totalSteps = 10;
  const [cards, setCards] = useState<strapiDoc>();
  console.log(cards , user)
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const query = {
          populate: {
            '0': 'cards',
            cards: {
              populate: '*',
            },
          },
        };
        const response = await fetchStrapiSingle('demographic-card', query);
        if (response) {
          setCards(response.data);
        }
      } catch (error) {
        console.error('Error fetching cards', error);
      }
    };
    fetchCards();
  }, []);// Estado para el paso actual

  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent
                currentStep={currentStep}
                totalSteps={totalSteps}
              />
              </div>
              <div className="col-lg-5 col-12">
                <h1>Datos Sociodemográficos</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green350">Quédate tranquila. Tus datos siempre están protegidos.</p>
                  <p>El siguiente cuestionario busca recopilar información relevante sobre tu perfil sociodemográfico para poder entender y apoyar mejor tus necesidades durante este proceso. Por favor, ten en cuenta que tienes derecho a una orientación y asesoramiento, basados en información clara y sin prejuicios.</p>
                </article>
                <SocioDemographicsComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
                <figure className="image-350">
                  <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
                </figure>
              </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SocioDemographicsScreen;