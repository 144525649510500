import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import {
  fetchAuthSession,
  signUp,
  signIn,
  signOut,
  updateUserAttributes,
} from 'aws-amplify/auth';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { getPasswordStrength } from '../../../utils/utils';
import { IUser, UserSource, UserStatus } from '../../../types/userInterface';
import { searchUsers, signUpUser } from '../../../api/usersApi';
import { useAlert } from '../../../hooks/useAlerts';
import LoaderComponent from '../../Loader/loader.component';

const SignUpComponent = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisibleTwo, setIsPasswordVisibleTwo] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [privacidad, setPrivacidad] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailMatch, setEmailMatch] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [touchedConfirmEmail, setTouchedConfirmEmail] = useState(false);
  const [touchedConfirmPassword, setTouchedConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  //Custom Hook to mange alerts
  const { setError } = useAlert();
  const navigate = useNavigate();

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    setEmailMatch(newEmail === confirmEmail);
  };

  const handleConfirmEmailChange = (newConfirmEmail: string) => {
    setConfirmEmail(newConfirmEmail);
    setEmailMatch(email === newConfirmEmail);
  };

  const getPasswordStrengthText = (strength: number) => {
    switch (strength) {
      case 1:
        return 'Débil';
      case 2:
        return 'Mediana';
      case 3:
        return 'Fuerte';
      default:
        return '';
    }
  };

  const validatePassword = (password: string) => {
    setPassword(password);
    const strength = getPasswordStrength(password);
    setPasswordStrength(strength);

    let message = '';
    if (password.length < 8 || password.length > 15) {
      message += 'Escribe entre 8 y 15 caracteres. ';
    }
    if (!/\d/.test(password) || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      message += 'Utiliza letras, números y símbolos. ';
    }
    if (!/[A-Z]/.test(password)) {
      message += 'Utiliza al menos una letra mayúscula. ';
    }
    setAlertMessage(message);
    setIsAlertVisible(message.length > 0);
  };

  const handleConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    if (password !== confirmPassword) {
      setAlertMessage('Las contraseñas no coinciden.');
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 2000);
    }
  };

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  const handleConfirmEmailBlur = () => {
    setTouchedConfirmEmail(true);
  };

  const handleConfirmPasswordBlur = () => {
    setTouchedConfirmPassword(true);
  };

  const dataBaseSignUp = async (email: string) => {
    try {
      const authSession = await fetchAuthSession();
      const jwtToken = authSession?.tokens?.idToken?.toString() || '';
      let dbUser: Partial<IUser> = {};
      const searchUser = await searchUsers({ email }, jwtToken);
      if (searchUser.docs.length > 0) {
        dbUser = searchUser.docs[0];
      } else {
        dbUser = await signUpUser(
          {
            source: UserSource.OLA,
            status: UserStatus.ACTIVE,
            email,
            step: 1,
          },
          jwtToken
        );
      }
      console.log(dbUser, 'DBUsser');
      await updateUserAttributes({
        userAttributes: {
          'custom:olaUserId': dbUser._id,
        },
      });
      navigate('/informacion-de-contacto');
    } catch (error) {
      setError('Error al enviar el código de confirmación');
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await signOut();
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: true,
        },
      });
      await signIn({ username: email, password });
      await dataBaseSignUp(email);
    } catch (error) {
      setError(error as string);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="email" className="form-label label--icon">
              Ingresa tu correo <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Esta información es importante para poder tener un medio de
                    contacto durante este procedimiento.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="tucorreo@dominio.com.cl"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          </div>
          <div className="form-row">
            <label
              htmlFor="email"
              className={`form-label label--icon ${
                !emailMatch && touchedConfirmEmail ? 'error' : ''
              }`}
            >
              Confirma tu correo <small className="required">*</small>
            </label>
            <input
              type="email"
              className={`form-control ${
                !emailMatch && touchedConfirmEmail ? 'error' : ''
              }`}
              id="email"
              placeholder="tucorreo@dominio.com.cl"
              value={confirmEmail}
              onChange={(e) => handleConfirmEmailChange(e.target.value)}
              onBlur={handleConfirmEmailBlur}
            />
            {!emailMatch && touchedConfirmEmail && (
              <Alert variant="danger" className="alert--small mt-3">
                Los correos electrónicos no coinciden.
              </Alert>
            )}
            <div className="password-rules">
              <p className="text-14">No se permiten correos institucionales</p>
            </div>
          </div>
          <div className="form-row">
            <label htmlFor="password" className="form-label label--icon">
              Contraseña <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Este es un mecanismo de seguridad para que tu información
                    sea confidencial
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="form-password">
              <button
                type="button"
                className="toggle-password"
                onClick={(e) => {
                  e.preventDefault();
                  setIsPasswordVisible(!isPasswordVisible);
                }}
              >
                <span className="material-icons-outlined icon">
                  {isPasswordVisible ? 'visibility_off' : 'visibility'}
                </span>
              </button>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                className="form-control"
                id="password"
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={(e) => validatePassword(e.target.value)}
              />
            </div>
            <div className="password-rules">
              <ul>
                <li>Escribe entre 8 y 15 caracteres</li>
                <li>Utiliza letras, números y símbolos</li>
                <li>Utiliza al menos una letra mayúscula </li>
              </ul>
            </div>
          </div>
          <div className="form-row">
            <small>{getPasswordStrengthText(passwordStrength)}</small>
            <div className="password-strength">
              <div
                className="password-strength__bar"
                style={{
                  width: `${passwordStrength * 33}%`,
                  backgroundColor:
                    passwordStrength === 2
                      ? '#FFAB48'
                      : passwordStrength === 1
                      ? '#EBE18C'
                      : '#46BC7C',
                }}
              ></div>
            </div>
          </div>
          <div className="form-row">
            <label
              htmlFor="password"
              className={`form-label label--icon ${
                isAlertVisible && touchedConfirmPassword ? 'error' : ''
              }`}
            >
              Confirmación de Contraseña <small className="required">*</small>
            </label>
            <div className="form-password">
              <button
                type="button"
                className="toggle-password"
                onClick={(e) => {
                  e.preventDefault();
                  setIsPasswordVisibleTwo(!isPasswordVisibleTwo);
                }}
              >
                <span className="material-icons-outlined icon">
                  {isPasswordVisibleTwo ? 'visibility_off' : 'visibility'}
                </span>
              </button>
              <input
                type={isPasswordVisibleTwo ? 'text' : 'password'}
                className={`form-control ${
                  isAlertVisible && touchedConfirmPassword ? 'error' : ''
                }`}
                id="password"
                placeholder="Ingresa tu contraseña"
                value={confirmPassword}
                onChange={(e) => handleConfirmPassword(e.target.value)}
                onBlur={handleConfirmPasswordBlur}
              />
            </div>
            {isAlertVisible && touchedConfirmPassword && (
              <Alert variant="danger" className="alert--small mt-3">
                {alertMessage}
              </Alert>
            )}
          </div>
          <div className="form-row pb-4">
            <div className="form-check check--flex">
              <input
                className="form-check-input requiredField"
                type="checkbox"
                name="acepto-privacidad"
                id="privacidad"
                value="privacidad"
                checked={privacidad}
                onChange={(e) => setPrivacidad(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="privacidad">
                He leído y aceptado la <a href="/">Política de Privacidad</a>
              </label>
            </div>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={
                password !== confirmPassword ||
                passwordStrength < 2 ||
                !emailMatch ||
                !privacidad
              }
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUpComponent;
