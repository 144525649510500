import api from './index';
import { IUser, IUserPaginate } from '../types/userInterface';

interface AxiosError {
  error: string;
}

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const signUpUser = async (user: Partial<IUser>, token: string) => {
  console.log('------ signUpUser ------', user);
  const response = await api.post<Partial<IUser> | AxiosError>('/user', user, {
    headers: {
      ...commonHeaders,
      Authorization: `${token}`,
    },
  });
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as Partial<IUser>;
};

export const updateUser = async (
  userId: string,
  user: Partial<IUser>,
  token: string
) => {
  const response = await api.put<Partial<IUser> | AxiosError>(
    `/user/${userId}`,
    user,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as Partial<IUser>;
};

export const deleteUser = async (userId: string, token: string) => {
  const response = await api.delete<Partial<IUser> | AxiosError>(
    `/user/${userId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as Partial<IUser>;
};

export const getUser = async (userId: string, token: string) => {
  const response = await api.get<Partial<IUser> | AxiosError>(
    `/user/${userId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as Partial<IUser>;
};

export const getUsers = async (
  token: string,
  props: {
    page?: number;
    limit?: number;
    searchText?: string;
  }
) => {
  const { page, limit, searchText } = props;
  const response = await api.get<IUserPaginate | AxiosError>('/user', {
    headers: {
      ...commonHeaders,
      Authorization: `${token}`,
    },
    params: {
      page,
      limit,
      searchText,
    },
  });
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IUserPaginate;
};

export const searchUsers = async (query: Partial<IUser>, token: string) => {
  const response = await api.post<IUserPaginate | AxiosError>(
    '/user/search',
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IUserPaginate;
};
