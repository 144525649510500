import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import PhoneComponent from '../../../components/Auth/Phone/phone.component';
import { IUser } from '../../../types/userInterface';

interface Props {
  user?: Partial<IUser>;
}

const PhoneScreen: React.FC<Props> = ({user}) => {
   const [currentStep] = useState(1);
   const totalSteps = 10;

   return (
    <>
      <HeaderShared user={user}/>
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent 
                currentStep={currentStep}
                totalSteps={totalSteps}
              />
              </div>
              <div className="col-lg-5 col-12">
                <h1>Información de contacto</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green350">
                  Quédate tranquila. Tus datos siempre están protegidos.
                  </p>
                 
                </article>
                <PhoneComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default PhoneScreen;