import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import SaludGinecologicaComponent from '../../../components/Auth/SaludGinecologica/saludGinecologica.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import { useAlert } from '../../../hooks/useAlerts';
import { IUser } from '../../../types/userInterface';

interface Props {
  user: Partial<IUser>;
}

const SaludGinecologicaScreen: React.FC<Props> = ({ user }) => {
  const [content, setContent] = useState<strapiDoc>();
  const [currentStep] = useState(6);
  const totalSteps = 10;
  const { setError } = useAlert();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('salud-ginecologica', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
                <StepperComponent
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              </div>
              <div className="col-lg-5 col-12">
                <h1>{content?.attributes.header || ''}</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green350">
                    {content?.attributes.subheader || ''}
                  </p>
                  <Markdown>{content?.attributes.content || ''}</Markdown>
                </article>
                <SaludGinecologicaComponent
                  tooltips={content?.attributes.Tooltip || []}
                  clinicList={content?.attributes.clinicList || []}
                  pregnancyMethod={content?.attributes.pregnancyMethod || []}
                />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
                <figure className="image-350">
                  <img
                    src="/assets/images/illustration/ilustration1.png"
                    alt="Te acompañamos"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SaludGinecologicaScreen;
