import React, {useEffect, useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import SexualidadEmocionesComponent from '../../../components/Auth/SexualidadEmociones/sexualidadEmociones.component';
import { fetchStrapiSingle } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import { useAlert } from '../../../hooks/useAlerts';
import Markdown from 'react-markdown';
import LoaderComponent from '../../../components/Loader/loader.component';
import { IUser } from '../../../types/userInterface';

interface Props {
  user?: Partial<IUser>
}

const SexualidadEmocionesScreen: React.FC<Props> = ({user}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentStep] = useState(8);
  const totalSteps = 10;
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<strapiDoc>();
  const { setError } = useAlert(); // Usa el hook para las alertas


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('sexualidad-y-emocion', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
    <HeaderShared user={user}/>
    <section className="module container">
    {loading && <LoaderComponent />}
      <div className="body-row row">
        <div className="col-12">
          <div className="module-bottom row">
            <div className="col-12">
              <PrivacyComponent />
            </div> 
          </div>
          <div className="auth-row module-bottom row">
            <div className="module-bottom40 col-12">
            <StepperComponent 
              currentStep={currentStep}
              totalSteps={totalSteps}
            />
            </div>
            <div className="col-md-5 col-12">
              <h1>{`${content?.attributes.header || ''}`}</h1>
              <article className="text-20 col-12 col-reset">
                <p className="text-green">
                  {`${content?.attributes.subheader || ''}`}
                </p>
                <Markdown>{`${content?.attributes.content || ''}`}</Markdown>
              </article>
              {content?.attributes.questions?.length && (
                <SexualidadEmocionesComponent
                  questions={content?.attributes.questions || []}
                  confianzaList={content?.attributes.confianzaList || []}
                />
              )}
            </div>
            <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
          
        </div>
      </div>
    </section>
    <FooterShared />
  </>
  );
};

export default SexualidadEmocionesScreen;