import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import HomeScreen from './pages/Home/home.screen';

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';

import ScrollToTop from './components/ScollToTop/scrollToTop.component';
import LoginScreen from './pages/Auth/Login/login.screen';
import ForgotPasswordScreen from './pages/Auth/ForgotPassword/forgotPassword.screen';
import UpdatePasswordScreen from './pages/Auth/UpdatePassword/updatePassword.screen';
import SignUpScreen from './pages/Auth/SignUp/signUp.screen';
import PhoneScreen from './pages/Auth/Phone/phone.screen';
import ActiveCodeScreen from './pages/Auth/ActiveCode/activeCode.screen';
import CodePhoneScreen from './pages/Auth/ActiveCode/codePhone/codePhone.screen';
import CodeEmailScreen from './pages/Auth/ActiveCode/codeEmail/codeEmail.screen';
import SocioDemographicsScreen from './pages/Auth/SocioDemographics/socioDemographics.screen';
import CausalesScreen from './pages/Auth/Causales/causales.screen';
import SocioCulturalScreen from './pages/Auth/SocioCultural/socioCultural.screen';
import SaludEmocionesScreen from './pages/Auth/SaludEmociones/saludEmociones.screen';
import SexualidadEmocionesScreen from './pages/Auth/SexualidadEmociones/sexualidadEmociones.screen';
import ViolentometroScreen from './pages/Auth/Violentometro/violentometro.screen';
import IncidenciasScreen from './pages/Auth/Incidencias/incidencias.screen';
import ProcessScreen from './pages/Dashboard/Process/process.screen';
import ProcessAcceptedScreen from './pages/Dashboard/Process/processAccepted.screen';
import AcompanamientoScreen from './pages/Dashboard/Acompanamiento/acompanamiento.screen';
import LlamadaScreen from './pages/Dashboard/Llamada/llamada.screen';
import GestionScreen from './pages/Dashboard/Gestion/gestion.screen';
import GestionAprobacionScreen from './pages/Dashboard/Gestion/gestionAprobacion.screen';
import GestoraScreen from './pages/Dashboard/Gestora/gestora.screen';
import ChatGestoraScreen from './pages/Dashboard/Chat/ChatGestora/chatboxGestora.screen';
import ProcessContactScreen from './pages/Dashboard/Process/processContact.screen';
import BitacoraScreen from './pages/Dashboard/Bitacora/bitacora.screen';
import EvaluacionHospitalScreen from './pages/Dashboard/Evaluaciones/evaluacionHospital.screen';
import EvaluacionNoHospitalScreen from './pages/Dashboard/Evaluaciones/evaluacionNoHospital.screen';
import EvaluacionHospitalLey21Screen from './pages/Dashboard/Evaluaciones/evaluacionHospitalLey21.screen';
import SeguimientoScreen from './pages/Dashboard/Seguimiento/seguimiento.screen';
import { getCurrentUser } from 'aws-amplify/auth';
import { getCurrentAuthenticatedUser } from './utils/utils';
import { IUser, UserSteps } from './types/userInterface';
import ChatAcompananteScreen from './pages/Dashboard/Chat/ChatAcompanante/chatboxAcompanante.screen';
import LoaderComponent from './components/Loader/loader.component';
import SaludGinecologicaScreen from './pages/Auth/SaludGinecologica/saludGinecologica.screen';

Amplify.configure(config);

function App() {
  const [hasLogin, setHasLogin] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');

  const mainRoute = (user: Partial<IUser>) => {
    if (!hasLogin) {
      return <LoginScreen />;
    }
    const { step } = user;
    switch (step) {
      case UserSteps.PROCEDIMIENTO_COMPLETO:
        return <SeguimientoScreen user={user} />;
      case UserSteps.INFORMACION_DE_CONTACTO:
        return <PhoneScreen user={user} />;
      case UserSteps.CODIGO_DE_ACTIVACION:
        return <ActiveCodeScreen user={user} />;
      case UserSteps.DATOS_SOCIODEMOGRÁFICOS:
        return <SocioDemographicsScreen user={user} />;
      case UserSteps.CAUSALES:
        return <CausalesScreen />;
      case UserSteps.CONTEXTO_SOCIOCULTURAL:
        return <SocioCulturalScreen user={user} />;
      case UserSteps.SALUD_GINECOLOGICA:
        return <SaludGinecologicaScreen user={user} />;
      case UserSteps.SALUD_EMOCIONES:
        return <SaludEmocionesScreen user={user} />;
      case UserSteps.SEXUALIDAD_EMOCIONES:
        return <SexualidadEmocionesScreen user={user} />;
      case UserSteps.VIOLENTOMETRO:
        return <ViolentometroScreen user={user} />;
      case UserSteps.INCIDENCIAS:
        return <IncidenciasScreen user={user} />;
      case UserSteps.MI_PROCESO:
        return <ProcessScreen />;
      case UserSteps.ACOMPANAMIENTO:
        return <AcompanamientoScreen />;
      default:
        return <ProcessScreen />;
    }
  };

  useEffect(() => {
    const checkLogin = async () => {
      try {
        setIsLoading(true);
        const currentUser = await getCurrentUser();
        if (currentUser) {
          setHasLogin(true);
          const user = await getCurrentAuthenticatedUser();
          if (user) {
            setUser(user.olaUser);
            setToken(user.jwtToken);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    checkLogin();
  }, []);

  useEffect(() => {}, [user]);

  return (
    <main className="app-main">
      {!isLoading ? (
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={mainRoute(user)} />
              <Route path="/iniciar-sesion" element={<LoginScreen />} />
              <Route
                path="/recuperar-contrasena"
                element={<ForgotPasswordScreen />}
              />
              <Route
                path="/actualizar-contrasena"
                element={<UpdatePasswordScreen />}
              />
              <Route path="/crear-cuenta" element={<SignUpScreen />} />
              <Route
                path="/informacion-de-contacto"
                element={<PhoneScreen />}
              />
              <Route
                path="/codigo-de-activacion"
                element={<ActiveCodeScreen />}
              />
              <Route path="/codigo-telefonico" element={<CodePhoneScreen />} />
              <Route path="/codigo-email" element={<CodeEmailScreen />} />
              <Route
                path="/datos-sociodemograficos"
                element={<SocioDemographicsScreen />}
              />
              <Route path="/causales" element={<CausalesScreen />} />
              <Route
                path="/contexto-sociocultural"
                element={<SocioCulturalScreen />}
              />
              <Route
                path="/salud-ginecologica"
                element={<SaludGinecologicaScreen user={user} />}
              />
              <Route
                path="/salud-y-emociones"
                element={<SaludEmocionesScreen />}
              />
              <Route
                path="/sexualidad-y-emociones"
                element={<SexualidadEmocionesScreen />}
              />
              <Route path="/violentometro" element={<ViolentometroScreen />} />
              <Route
                path="/incidencias"
                element={<IncidenciasScreen user={user} />}
              />
              <Route path="/mi-proceso" element={<ProcessScreen />} />
              <Route
                path="/mi-proceso-aceptado"
                element={<ProcessAcceptedScreen />}
              />
              <Route
                path="/seguimiento-contacto"
                element={<ProcessContactScreen />}
              />
              <Route
                path="/acompanamiento"
                element={<AcompanamientoScreen />}
              />
              <Route
                path="/chat-acompanante"
                element={<ChatAcompananteScreen user={user} token={token} />}
              />
              <Route path="/chat-gestora" element={<ChatGestoraScreen />} />
              <Route path="/llamada" element={<LlamadaScreen />} />
              <Route path="/gestion" element={<GestionScreen />} />
              <Route
                path="/gestion-aprobacion"
                element={<GestionAprobacionScreen />}
              />
              <Route
                path="/gestora"
                element={<GestoraScreen user={user} token={token} />}
              />
              <Route path="/bitacora" element={<BitacoraScreen />} />
              <Route
                path="/evaluacion-hospital"
                element={<EvaluacionHospitalScreen />}
              />
              <Route
                path="/evaluacion-no-asiste-hospital"
                element={<EvaluacionNoHospitalScreen />}
              />
              <Route
                path="/evaluacion-hospital-ley-21"
                element={<EvaluacionHospitalLey21Screen />}
              />
              <Route path="/seguimiento" element={<SeguimientoScreen />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      ) : (
        <LoaderComponent />
      )}
    </main>
  );
}

export default App;
