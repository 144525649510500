import React, {useEffect, useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import { Alert } from 'react-bootstrap';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import SignUpComponent from '../../../components/Auth/SignUp/signUp.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { useAlert } from '../../../hooks/useAlerts';
import LoaderComponent from '../../../components/Loader/loader.component';
import { fetchStrapiSingle } from '../../../api/strapi';
import Markdown from 'react-markdown';

const SignUpScreen: React.FC = () => {
  const [currentStep] = useState(1);
  const totalSteps = 3;
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<strapiDoc>();
  const { setError } = useAlert(); // Usa el hook para las alertas

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('vamos-a-crear-tu-cuenta', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  console.log(content)

  return (
    <>
      <HeaderShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              </div>
              <div className="col-lg-5 col-12">
                <h1>{`${content?.attributes.header || ''}`}</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green350">
                    {`${content?.attributes.subheader || ''}`}
                  </p>
                  <Markdown>{`${content?.attributes.content || ''}`}</Markdown>
                </article>
                <Alert variant="success" className="alert--soft-success">
                  <i className="icon fa-regular fa-flag"></i>
                  <article>
                    <Markdown>{`${content?.attributes.alert || ''}`}</Markdown>
                  </article>
                </Alert>
                <SignUpComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SignUpScreen;