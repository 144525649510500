import React from 'react';
import CardImageComponent from './cardImage.component';

interface CardData {
  imageUrl?: string;
  title?: string;
  subtitle?: string | null;
  content?: string;
  URL?: string | null;
  URLText?: string | null;
  ExternalLink?: string | null;
}

interface Props {
  title?: string;
  titleSecond?: string;
  color?: string;
  cards: CardData[];
}

const CardWrapperComponent: React.FC<Props> = ({
  title,
  titleSecond,
  cards,
  color,
}) => {
  return (
    <section className="module">
      <div className="container">
        {title || titleSecond ? (
          <div className="row">
            <div className="col-12">
              {title ? (
                <h2
                  className={`text-40 text-center ${
                    color ? 'text-' + color : 'text-pink400'
                  }`}
                >
                  {title}
                </h2>
              ) : null}

              {titleSecond ? (
                <p className="text-24 text-center">{titleSecond}</p>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className="module-top40">
          <div className="row">
            {cards.map((card, index) => (
              <div key={index} className="mb-4 card-row__col col-lg-4 col-12">
                <CardImageComponent
                  imageUrl={card?.imageUrl}
                  title={card?.title}
                  subtitle={card?.subtitle}
                  content={card?.content}
                  URL={card?.URL}
                  URLText={card?.URLText}
                  ExternalLink={card?.ExternalLink}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardWrapperComponent;
