import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../../shared/header.shared';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../../components/Stepper/stepper.component';
import FooterShared from '../../../../shared/footer.shared';
import CodeEmailComponent from '../../../../components/Auth/ActiveCode/codeEmail/codeEmail.component';
import { fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth';

const CodeEmailScreen = () => {
  const [currentStep] = useState(1);
  const [userAttributes, setUserAttributes] =
    useState<FetchUserAttributesOutput>({});
  const totalSteps = 10;

  useEffect(() => {
    const fetchData = async () => {
      const userAttributes = await fetchUserAttributes();
      setUserAttributes(userAttributes);
    };
    fetchData();
  }, []);
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent 
                 currentStep={currentStep}
                 totalSteps={totalSteps}
                />
              </div>
              <div className="col-lg-5 col-12">
                <h1 className="mb-4">Código de activación</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-icon">
                    <i className="fa-regular fa-envelope"></i> Te acabamos de
                    enviar el código a tu correo:
                    </p>
                    <p className="text-24">
                    <strong>
                      {userAttributes.email?.split('@')[0].slice(0, 2) || ''}
                      <span className="text-hide">
                        {userAttributes.email &&
                          userAttributes.email
                            ?.split('@')[0]
                            .slice(2)
                            .split('')
                            .map((a, i) => (
                              <i key={i} className="fa-solid fa-circle"></i>
                            ))}
                      </span>
                      {`@${
                        userAttributes.email
                          ? userAttributes.email?.split('@')[1]
                          : ''
                      }` || ''}
                    </strong>
                  </p>
                </article>
                <CodeEmailComponent />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default CodeEmailScreen;
