import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsGestora.json';
import { MessageBoxType } from 'react-chat-elements';
import { IUser } from '../../../types/userInterface';
import { fetchChatMessages } from '../../../api/chatApi';
import ChatComponent from '../../../components/Chat/chat.component';

interface ChatGestoraScreenProps {
  user: Partial<IUser>;
  token: string;
}

const GestoraScreen: React.FC<ChatGestoraScreenProps> = ({ 
  user,
  token,
}) => {

  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<MessageBoxType[]>([]);

  const steps = [
    { title: "Completa tu perfil", description: "Registrada", status: "status--success", completed: true, color: "green350" },
    { title: "Te presentamos a tu acompañante", description: "En proceso", status: "status--process", completed: false, color: "pink400" },
    { title: "Llamada telefónica", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Accede a la ley 21.030", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Seguimos en contacto", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];

  const fetchOldMessages = async (roomId: string, token: string) => {
    try {
      setLoading(true);
      const response = await fetchChatMessages(roomId, token);
      const userId = user._id;

      const messages = response.docs.reverse().map((doc) => {
        const message: MessageBoxType = {
          id: doc._id,
          position: doc.sender === userId ? 'right' : 'left',
          type: 'text',
          text: doc.message,
          date: new Date(doc.createdAt),
          avatar:
            doc.sender === userId
              ? '/assets/images/icons/user-small.svg'
              : '/assets/images/icons/user-pic-2.svg',
          title: doc.sender === userId ? 'Tu' : doc.senderName,
          focus: false,
          forwarded: false,
          replyButton: false,
          removeButton: false,
          titleColor: '#000000',
          status: doc.sender === userId ? 'sent' : 'received',
          notch: true,
          retracted: false,
        };
        return message;
      });

      setMessages([...messages]);
    } catch (error) {
      console.error('Error fetching old messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const setNewMessage = (msg: MessageBoxType) => {
    console.log('Received message:', msg);
    setMessages((prevMessages) => [...prevMessages, msg]);
    // scroll to the bottom of the chat element with class .rce-mlist
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        console.log('Chat container found:', chatContainer.scrollHeight);
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  useEffect(() => {
    if (user.assignedTo?.chatRoomId && token) {
      fetchOldMessages(user.assignedTo?.chatRoomId, token);
    }
  }, [user]);

  return (
    <>
      <HeaderShared />
      <section className="module-top container">

        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
            
            </div>
            {loading ? (
                <div className="text-center col-12">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  <ChatComponent
                    user={user}
                    roomId={user.assignedTo?.chatRoomId || ''}
                    token={token}
                    messages={messages}
                    setNewMessage={setNewMessage}
                  />
                </div>
              )}
          </div>
        </div>
      </section>
      <CardWrapperComponent color="base" title="¿Qué hace una gestora?" cards={cards} />
      <FooterShared />
    </>
  );
};

export default GestoraScreen;