import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';
import Swal from 'sweetalert2';
import { FileWithPath } from 'react-dropzone';
import embarazosData from '../../../utils/embarazos.json';
import tiempoEmbarazo from '../../../utils/semanas-dias.json';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import DropZoneComponent from '../../Form/DropZone/dropZone.component';
import CalculadoraEmbarazo from '../../Form/CalculadoraEmbarazo/calculadoraEmbarazo.component';
import { ITooltip } from '../../../types/strapiDocs';
import LoaderComponent from '../../Loader/loader.component';
import {
  getCurrentAuthenticatedUser,
  handleUploadFile,
} from '../../../utils/utils';
import { IUser } from '../../../types/userInterface';
import { updateUser } from '../../../api/usersApi';

interface Props {
  tooltips: ITooltip[];
  clinicList: {
    id: number;
    Nombre: string;
  }[];
  pregnancyMethod: {
    id: number;
    Nombre: string;
  }[];
}

const SaludGinecologicaComponent = ({
  tooltips,
  clinicList,
  pregnancyMethod,
}: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({});
  const [semanasResult, setSemanasResult] = useState({
    semanas: '',
    dias: '',
  });
  const [formularioValido, setFormularioValido] = useState(false);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [filesAdditional, setFilesAdditional] = useState<FileWithPath[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [mostrarCalculadora, setMostrarCalculadora] = useState(true);

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();

    camposRequeridos.forEach((campo) => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto);
        }
      }
    });

    gruposRadio.forEach((nombre) => {
      const seleccionado =
        document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;
    });

    setFormularioValido(todosCompletos);
  };

  const handleEmbarazoInfo = (semanas: number, dias: number) => {
    setUser({
      ...user,
      saludGinecologica: {
        ...user.saludGinecologica,
        pregnancyCalculator: {
          ...user.saludGinecologica?.pregnancyCalculator,
          result: `${semanas} semanas y ${dias} días`,
          weeks: semanas,
          days: dias,
        },
      },
    });
  };

  const handleFilesChanged = (files: FileWithPath[]) => {
    if (files.length > 0) {
      setFiles(files);
    }
  };

  const handleFilesAdditionalChanged = (files: FileWithPath[]) => {
    if (files.length > 0) {
      setFilesAdditional(files);
    }
  };

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = {
        ...user,
      };

      if (files.length > 0) {
        const keys = await Promise.all(
          files.map((file) =>
            handleUploadFile(
              `${currentUser.userId}`,
              file,
              'ultrasoundReportFile'
            )
          )
        );
        currentUser.saludGinecologica = {
          ...currentUser.saludGinecologica,
          ultrasoundReportFile: keys.map((key) => key || ''),
        };
      }

      if (filesAdditional.length > 0) {
        const keys = await Promise.all(
          filesAdditional.map((file) =>
            handleUploadFile(`${currentUser.userId}`, file, 'additionalFiles')
          )
        );
        currentUser.saludGinecologica = {
          ...currentUser.saludGinecologica,
          additionalFiles: keys.map((key) => key || ''),
        };
      }

      await updateUser(user._id || '', { ...currentUser, step: 7 }, token);
      navigate('/salud-y-emociones');
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (!currentUser) {
          navigate('/');
        }
        setToken(currentUser?.jwtToken || '');
        setUser(currentUser?.olaUser || {});
        const pregnancyCalculator =
          currentUser?.olaUser?.saludGinecologica?.pregnancyCalculator;
        if (pregnancyCalculator) {
          setSemanasResult({
            semanas: (pregnancyCalculator?.weeks || '').toString(),
            dias: (pregnancyCalculator?.days || '').toString(),
          });
          setMostrarCalculadora(false);
        }
        verifyFields();
      } catch (error) {
        setError(`${error}`);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    verifyFields();
  }, [user]);

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label className="form-label label--icon">
              Contando éste embarazo, ¿Cuántas veces has estado embarazada?{' '}
              <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown>
                      {`${
                        tooltips.find(({ field }) => field === 'embarazos')
                          ?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select requiredField"
              aria-label="¿Cuántas veces has estado embarazada?"
              defaultValue={'default'}
              name="embarazos"
              value={user.saludGinecologica?.pregnancies || 'default'}
              onChange={(e) => {
                setUser({
                  ...user,
                  saludGinecologica: {
                    ...user.saludGinecologica,
                    pregnancies: e.target.value,
                  },
                });
              }}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {embarazosData.map((embarazo) => (
                <option key={embarazo.id} value={embarazo.value}>
                  {embarazo.label}
                </option>
              ))}
            </select>
          </div>
          {user.saludGinecologica?.pregnancies !== '0' && (
            <>
              <div className="form-row">
                <label className="form-label label--icon">
                  Número de partos por cesárea
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <Markdown>
                          {`${
                            tooltips.find(({ field }) => field === 'cesaeas')
                              ?.content || ''
                          }`}
                        </Markdown>
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <select
                  className="form-select"
                  aria-label="Número de partos por cesárea"
                  defaultValue={'default'}
                  name="cesaeas"
                  value={user.saludGinecologica?.cesareanBirth || 'default'}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      saludGinecologica: {
                        ...user.saludGinecologica,
                        cesareanBirth: e.target.value,
                      },
                    });
                  }}
                >
                  <option value="default" disabled>
                    Elige una de las opciones
                  </option>
                  {embarazosData
                    .filter((eD) => {
                      const pregnancies =
                        user.saludGinecologica?.pregnancies || '0';
                      return eD.id <= Number(pregnancies);
                    })
                    .map((cesareas) => (
                      <option key={cesareas.id} value={cesareas.value}>
                        {cesareas.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Número de partos vaginales
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <Markdown>
                          {`${
                            tooltips.find(({ field }) => field === 'vaginales')
                              ?.content || ''
                          }`}
                        </Markdown>
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <select
                  className="form-select"
                  aria-label="Número de partos vaginales"
                  defaultValue={'default'}
                  name="vaginales"
                  value={user.saludGinecologica?.vaginalBirth || 'default'}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      saludGinecologica: {
                        ...user.saludGinecologica,
                        vaginalBirth: e.target.value,
                      },
                    });
                  }}
                >
                  <option value="default" disabled>
                    Elige una de las opciones
                  </option>
                  {embarazosData
                    .filter((eD) => {
                      const pregnancies =
                        user.saludGinecologica?.pregnancies || '0';
                      return eD.value <= Number(pregnancies);
                    })
                    .map((vaginal) => (
                      <option key={vaginal.id} value={vaginal.value}>
                        {vaginal.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Número de abortos (inducidos, por causales o espontáneos){' '}
                  <small className="required">*</small>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <Markdown>
                          {`${
                            tooltips.find(({ field }) => field === 'abortos')
                              ?.content || ''
                          }`}
                        </Markdown>
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <select
                  className="form-select requiredField"
                  aria-label=" Número de abortos"
                  defaultValue={'default'}
                  name="abortos"
                  value={user.saludGinecologica?.abortions || 'default'}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      saludGinecologica: {
                        ...user.saludGinecologica,
                        abortions: e.target.value,
                      },
                    });
                  }}
                >
                  <option value="default" disabled>
                    Elige una de las opciones
                  </option>
                  {embarazosData
                    .filter((eD) => {
                      const pregnancies =
                        user.saludGinecologica?.pregnancies || '0';
                      return eD.value <= Number(pregnancies);
                    })
                    .map((aborto) => (
                      <option key={aborto.id} value={aborto.value}>
                        {aborto.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Intentaste abortar antes en este embarazo?{' '}
                  <small className="required">*</small>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <Markdown>
                          {`${
                            tooltips.find(
                              ({ field }) => field === 'abortarHistorial'
                            )?.content || ''
                          }`}
                        </Markdown>
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <div className="form-check-multi">
                  {[
                    {
                      id: 'abortoSi',
                      value: 'si',
                      label: 'Sí',
                      checked:
                        user.saludGinecologica?.tryToAbortEarlier === 'si',
                    },
                    {
                      id: 'abortoNo',
                      value: 'no',
                      label: 'No',
                      checked:
                        user.saludGinecologica?.tryToAbortEarlier === 'no',
                    },
                    {
                      id: 'abortoNoSeguro',
                      value: 'no-estoy-segura',
                      label: 'No estoy segura',
                      checked:
                        user.saludGinecologica?.tryToAbortEarlier ===
                        'no-estoy-segura',
                    },
                  ].map((aborto) => (
                    <div className="form-check" key={aborto.id}>
                      <input
                        className="form-check-input requiredField"
                        type="radio"
                        name="abortarHistorial"
                        id={aborto.id}
                        value={aborto.value}
                        checked={aborto.checked}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            saludGinecologica: {
                              ...user.saludGinecologica,
                              tryToAbortEarlier: e.target.value,
                            },
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor={aborto.id}>
                        {aborto.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Qué previsión de salud tienes?{' '}
              <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown>
                      {`${
                        tooltips.find(
                          ({ field }) => field === 'metodoAnticonceptivo'
                        )?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select requiredField"
              aria-label="¿Qué previsión de salud tienes?"
              defaultValue={''}
              name="metodoAnticonceptivo"
              value={user.saludGinecologica?.healthForecast || ''}
              onChange={(e) => {
                setUser({
                  ...user,
                  saludGinecologica: {
                    ...user.saludGinecologica,
                    healthForecast: e.target.value,
                  },
                });
              }}
            >
              <option value="" disabled>
                Elige una de las opciones
              </option>
              {clinicList.map((metodo) => (
                <option key={metodo.id} value={metodo.Nombre}>
                  {metodo.Nombre}
                </option>
              ))}
            </select>
          </div>
          {(!user.saludGinecologica?.healthForecast ||
            user.saludGinecologica?.healthForecast === '' ||
            user.saludGinecologica?.healthForecast === 'Otro') && (
            <div className="form-row">
              <label className="form-label label--icon">
                Si no está la opción, menciónala aquí
              </label>
              <textarea
                className="form-control"
                id="other"
                rows={3}
                value={user.saludGinecologica?.healthForecastOther || ''}
                onChange={(e) => {
                  setUser({
                    ...user,
                    saludGinecologica: {
                      ...user.saludGinecologica,
                      healthForecastOther: e.target.value,
                    },
                  });
                }}
              ></textarea>
            </div>
          )}
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Sabes a qué urgencia ginecológica o servicio de salud debes ir
              cuando lo necesitas?
              <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown>
                      {`${
                        tooltips.find(
                          ({ field }) => field === 'metodoAnticonceptivo'
                        )?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              {[
                {
                  id: 'urgenciaSi',
                  value: 'si',
                  label: 'Sí',
                  checked:
                    user.saludGinecologica?.healthServiceShouldGo === 'si',
                },
                {
                  id: 'urgenciaNo',
                  value: 'no',
                  label: 'No',
                  checked:
                    user.saludGinecologica?.healthServiceShouldGo === 'no',
                },
                {
                  id: 'urgenciaNoSeguro',
                  value: 'no-estoy-segura',
                  label: 'No estoy segura',
                  checked:
                    user.saludGinecologica?.healthServiceShouldGo ===
                    'no-estoy-segura',
                },
              ].map((urgencia) => (
                <div className="form-check" key={urgencia.id}>
                  <input
                    className="form-check-input requiredField"
                    type="radio"
                    name="urgencia"
                    id={urgencia.id}
                    value={urgencia.value}
                    checked={urgencia.checked}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        saludGinecologica: {
                          ...user.saludGinecologica,
                          healthServiceShouldGo: e.target.value,
                        },
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor={urgencia.id}>
                    {urgencia.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {user.saludGinecologica?.healthServiceShouldGo !== 'no' && (
            <div className="form-row">
              <label className="form-label label--icon">Escribe cuál</label>
              <textarea
                className="form-control"
                id="otherUrgencia"
                rows={3}
                value={user.saludGinecologica?.healthServiceShouldGoOther || ''}
                onChange={(e) => {
                  setUser({
                    ...user,
                    saludGinecologica: {
                      ...user.saludGinecologica,
                      healthServiceShouldGoOther: e.target.value,
                    },
                  });
                }}
              ></textarea>
            </div>
          )}
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Sabes cuántas semanas de embarazo tienes?
            </label>
            <div className="form-check-multi">
              {[
                {
                  id: 'semanasSi',
                  value: 'si',
                  label: 'Sí',
                  checked: !mostrarCalculadora,
                },
                {
                  id: 'semanasNo',
                  value: 'no',
                  label: 'No',
                  checked: mostrarCalculadora,
                },
              ].map((semanas) => (
                <div className="form-check" key={semanas.id}>
                  <input
                    className="form-check-input requiredField"
                    type="radio"
                    name="semanas"
                    id={semanas.id}
                    value={semanas.value}
                    checked={semanas.checked}
                    onChange={(e) =>
                      setMostrarCalculadora(e.target.value === 'no')
                    }
                  />
                  <label className="form-check-label" htmlFor={semanas.id}>
                    {semanas.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {!mostrarCalculadora && (
            <div className="form-row">
              <label className="form-label label--icon">
                ¿Cuántas semanas calculas que tienes?
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <Markdown>
                        {`${
                          tooltips.find(
                            ({ field }) => field === 'semanasEmbarazo'
                          )?.content || ''
                        }`}
                      </Markdown>
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <p className="text-gray600">
                Si tienes un ecografía, guíate de las semanas que te indica el
                reporte
              </p>
              <div className="row">
                <div className="col-6">
                  <label className="form-label label--icon">Semanas</label>
                  <select
                    className="form-select "
                    aria-label="Semanas de embarazo"
                    defaultValue={'default'}
                    name="semanasEmbarazo"
                    value={
                      user.saludGinecologica?.pregnancyCalculator?.weeks ||
                      'default'
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setSemanasResult({
                        ...semanasResult,
                        semanas: e.target.value,
                      });
                      if (semanasResult.dias !== '') {
                        setUser({
                          ...user,
                          saludGinecologica: {
                            ...user.saludGinecologica,
                            pregnancyCalculator: {
                              ...user.saludGinecologica?.pregnancyCalculator,
                              result: `${e.target.value} semanas y ${semanasResult.dias} días`,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <option value="default" disabled>
                      Elige una de las opciones
                    </option>
                    {tiempoEmbarazo.semanasEmbarazo.map((semanas) => (
                      <option key={semanas.id} value={semanas.id}>
                        {semanas.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  <label className="form-label label--icon">Días</label>
                  <select
                    className="form-select "
                    aria-label="Días de embarazo"
                    defaultValue={'default'}
                    name="diasEmbarazo"
                    value={
                      user.saludGinecologica?.pregnancyCalculator?.days ||
                      'default'
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setSemanasResult({
                        ...semanasResult,
                        dias: e.target.value,
                      });
                      if (semanasResult.semanas !== '') {
                        setUser({
                          ...user,
                          saludGinecologica: {
                            ...user.saludGinecologica,
                            pregnancyCalculator: {
                              ...user.saludGinecologica?.pregnancyCalculator,
                              result: `${semanasResult.semanas} semanas y ${e.target.value} días`,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <option value="default" disabled>
                      Elige una de las opciones
                    </option>
                    {tiempoEmbarazo.diasPostEmbarazo.map((dias) => (
                      <option key={dias.id} value={dias.id}>
                        {dias.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {mostrarCalculadora && (
            <div className="form-row">
              <CalculadoraEmbarazo onEmbarazoInfo={handleEmbarazoInfo} />
            </div>
          )}
          <div className="form-row">
            <h3 className="h4 text-regular">Ecografía y Documentos</h3>
            <p>
              <strong>(sólo si cuentas con ella)</strong>
            </p>
            <label className="form-label label--icon">
              Si necesitas una ecografía puedes acudir a{' '}
              <strong className="text-purple500">Aló-Eloísa</strong>{' '}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown>
                      {`${
                        tooltips.find(({ field }) => field === 'ecografia')
                          ?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <p className="text-14 text-gray800">
              Para que el proceso se lleve a cabo de la mejor manera, es muy
              importante tener claras las semanas de gestación y otra
              información relevante.{' '}
            </p>
            <p className="text-14 text-gray800">
              La ecografía es el método de comprobación que nos permite saber
              con exactitud cuantas semanas está cursando y otro tipo de
              diagnósticos clínicos.
            </p>
            <a
              href="https://aloeloisa.cl"
              target="_blank"
              rel="noreferrer"
              className="btn btn--type2"
            >
              Ver listado de Centros de Salud Amigables
            </a>
          </div>

          <div className="form-row">
            <label className="form-label label--icon">
              ¿Qué método utilizaste para comprobar tu embarazo?{' '}
              <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown>
                      {`${
                        tooltips.find(
                          ({ field }) => field === 'metodoComprobacion'
                        )?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <p className="text-gray800">Puedes seleccionar más de una opción</p>
            <div className="row-check-2 row">
              {pregnancyMethod.map((metodo) => (
                <div className="row-check-2__col col-6" key={metodo.id}>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="metodoComprobacion"
                      id={`${metodo.id}`}
                      value={metodo.Nombre}
                      checked={
                        user.saludGinecologica?.whatMethodUse?.includes(
                          metodo.Nombre
                        ) || false
                      }
                      onChange={(e) => {
                        const whatMethodUse =
                          user.saludGinecologica?.whatMethodUse || [];
                        const index =
                          user.saludGinecologica?.whatMethodUse?.findIndex(
                            (method) => method === e.target.value
                          );
                        if (index && index !== -1) {
                          whatMethodUse.splice(index, 1);
                        } else {
                          whatMethodUse.push(e.target.value);
                        }
                        setUser({
                          ...user,
                          saludGinecologica: {
                            ...user.saludGinecologica,
                            whatMethodUse,
                          },
                        });
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`${metodo.id}`}
                    >
                      {metodo.Nombre}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              Informe de Ecografía <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown>
                      {`${
                        tooltips.find(({ field }) => field === 'informe')
                          ?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <p className="icon-text text-gray700">
              <i className="icon icon--add-document"></i>
              Selecciona una imagen JPG o PDF (peso menor a 10 MB), puedes subir
              hasta 3 documentos
            </p>
            <DropZoneComponent onFilesChanged={handleFilesChanged} />
            <p className="mt-2">
              Por tu seguridad, estos datos serán destruidos en 60 días corridos
            </p>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              Archivos adicionales <small className="required">*</small>
            </label>
            <p className="icon-text text-gray700">
              <i className="icon icon--add-document"></i>
              Selecciona una imagen JPG o PDF (peso menor a 10 MB), puedes subir
              hasta 3 documentos
            </p>
            <DropZoneComponent onFilesChanged={handleFilesAdditionalChanged} />
            <p className="mt-2">
              Por tu seguridad, estos datos serán destruidos en 60 días corridos
            </p>
          </div>
          <div className="form-row">
            <p className="form-label label--icon">
              Revisa tu informe ecográfico y ve si coincide con alguno de los
              diagnósticos y patologías señaladas en la ley 21.030, causal 1 y
              causal 2:
            </p>
          </div>
          <div className="form-row module-top40">
            <p className="text-center">
              ¡Y recuerda! Si lo necesitas podemos acordar una llamada
            </p>
            <a href="tel:569982729131" className="btn btn--type2 btn--icon">
              <i className="icon icon--phone-call"></i>
              Información de aborto legal en Chile 569 982 729 131
            </a>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SaludGinecologicaComponent;
