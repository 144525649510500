import React, { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import { Link, useNavigate } from 'react-router-dom';
import { confirmUserAttribute, fetchUserAttributes, sendUserAttributeVerificationCode } from 'aws-amplify/auth';
import LoaderComponent from '../../../Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../../../utils/utils';
import { updateUser } from '../../../../api/usersApi';
import { useAlert } from '../../../../hooks/useAlerts';


const CodeEmailComponent: React.FC = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [disableResend, setDisableResend] = useState(true);
  const { setError, setSuccess } = useAlert(); // Usa el hook para las alertas
  const isCodeComplete = code.length === 6; // Asumiendo que el código tiene 6 caracteres
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const currentUser = await getCurrentAuthenticatedUser();
      await confirmUserAttribute({
        userAttributeKey: 'email',
        confirmationCode: code,
      });
      if (currentUser) {
        const { olaUser, jwtToken } = currentUser;
        const userId = olaUser._id || '';
        await updateUser(
          userId,
          {
            ...olaUser,
            step: 3,
            emailVerified: true,
          },
          jwtToken
        );
      }
      navigate('/datos-sociodemograficos');
    } catch (error) {
      setError('Código incorrecto');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      await sendUserAttributeVerificationCode({
        userAttributeKey: 'email',
      });
      setSuccess('Código reenviado con éxito.');
      setDisableResend(true);
      setTimeout(() => setDisableResend(false), 60000); // Bloquea el reenvío por 60 segundos
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'LimitExceededException') {
          setError('Has alcanzado el límite de intentos. Intenta nuevamente más tarde.');
        } else {
          setError(`Error inesperado: ${error.message}`);
        }
      } else {
        setError('Error desconocido al reenviar el código.');
      }
      console.error('Detalle del error:', error);
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        if (userAttributes) {
          const { email_verified } = userAttributes;
          if (email_verified === 'true') {
            navigate('/datos-sociodemograficos');
          } else {
            await sendUserAttributeVerificationCode({
              userAttributeKey: 'email',
            });
            await new Promise((resolve) =>
              setTimeout(() => {
                setDisableResend(false);
                resolve(true);
              }, 5000)
            );
          }
        }
      } catch (error) {
        setError(`${error}`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <VerificationInput
            value={code}
            onChange={setCode}
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected',
            }}
            length={6}
          />
        </div>
        <div className="form-row form--last module-top40">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={!isCodeComplete}
          >
            Siguiente
          </button>
        </div>
        <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
            disabled={disableResend}
            onClick={handleResendCode}
          >
            Reenviar código
          </button>
        </div>
        <div className="form-row form--last">
          <Link to="/codigo-telefonico" className="btn--simple">
          Enviar código a mi teléfono
          </Link>
        </div>
      </form>
    </div>
  );
};

export default CodeEmailComponent;
