import React, {useEffect, useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import { Alert } from 'react-bootstrap';
import SocioCulturalComponent from '../../../components/Auth/SocioCultural/socioCultural.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import { useAlert } from '../../../hooks/useAlerts';
import Markdown from 'react-markdown';
import { IUser } from '../../../types/userInterface';

interface Props {
  user?: Partial<IUser>;
}

const SocioCulturalScreen: React.FC<Props> = ({user}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentStep] = useState(5);
  const totalSteps = 10;
  const [content, setContent] = useState<strapiDoc>();
  const { setError } = useAlert(); // Usa el hook para las alertas

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('contexto-sociocultural', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user}/>
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div> 
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent 
                currentStep={currentStep}
                totalSteps={totalSteps}
              />
              </div>
              <div className="col-md-5 col-12">
                <h1>{`${content?.attributes.header || ''}`}</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green">
                    {`${content?.attributes.subheader || ''}`}
                  </p>
                  <Markdown>{`${content?.attributes.content || ''}`}</Markdown>
                </article>
                <Alert
                  variant="success"
                  className="alert--soft-success alert--complete"
                >
                  <Markdown
                    components={{
                      p: (props: React.DOMAttributes<HTMLParagraphElement>) => (
                        <p className="mb-3 text-base">{props.children || ''}</p>
                      ),
                    }}
                  >{`${content?.attributes.alert || ''}`}</Markdown>
                  <div className="col-12">
                    <i className="icon fa-regular fa-heart"></i>
                    <article>
                      <Markdown>
                        {content?.attributes.alertheart || ''}
                      </Markdown>
                    </article>
                  </div>
                </Alert>
                <SocioCulturalComponent
                  tooltips={content?.attributes.Tooltip || []}
                />
              </div>
              <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-center">
                <figure className="image-350">
                  <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
                </figure>
              </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SocioCulturalScreen;