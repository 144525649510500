import { useCallback } from "react";
import Swal from "sweetalert2";

export const useAlert = () => {
  const setError = useCallback((message: string) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    });
  }, []);

  const setSuccess = useCallback((message: string) => {
    Swal.fire({
      icon: "success",
      title: "¡Éxito!",
      text: message,
    });
  }, []);

  return { setError, setSuccess };
};
