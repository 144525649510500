import React, { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import { Link, useNavigate } from 'react-router-dom';
import { getCurrentAuthenticatedUser } from '../../../../utils/utils';
import { confirmUserAttribute, fetchUserAttributes, sendUserAttributeVerificationCode } from 'aws-amplify/auth';
import { updateUser } from '../../../../api/usersApi';
import { useAlert } from '../../../../hooks/useAlerts';
import LoaderComponent from '../../../Loader/loader.component';


const CodePhoneComponent: React.FC = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [disableResend, setDisableResend] = useState(true);
  const isCodeComplete = code.length === 6; // Asumiendo que el código tiene 6 caracteres
  const navigate = useNavigate();
  const {setError} = useAlert()


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = await getCurrentAuthenticatedUser();
      await confirmUserAttribute({
        userAttributeKey: 'phone_number',
        confirmationCode: code,
      });
      if (currentUser) {
        const { olaUser, jwtToken } = currentUser;
        const userId = olaUser._id || '';
        await updateUser(
          userId,
          {
            ...olaUser,
            step: 3,
            phoneVerified: true,
          },
          jwtToken
        );
      }
      navigate('/datos-sociodemograficos');
    } catch (error) {
      setError('Código incorrecto');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    await sendUserAttributeVerificationCode({
      userAttributeKey: 'phone_number',
    });
    setDisableResend(true);
    await new Promise((resolve) =>
      setTimeout(() => {
        setDisableResend(false);
        resolve(true);
      }, 10000)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        if (userAttributes) {
          const { phone_number_verified } = userAttributes;
          if (phone_number_verified === 'true') {
            navigate('/datos-sociodemograficos');
          } else {
            await sendUserAttributeVerificationCode({
              userAttributeKey: 'phone_number',
            });
            await new Promise((resolve) =>
              setTimeout(() => {
                setDisableResend(false);
                resolve(true);
              }, 5000)
            );
          }
        }
      } catch (error) {
        setError(`${error}`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);



  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <VerificationInput
            value={code}
            onChange={setCode}
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected',
            }}
            length={6}
          />
        </div>
        <div className="form-row form--last module-top40">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={!isCodeComplete}
          >
            Siguiente
          </button>
        </div>
        <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
            disabled={disableResend}
            onClick={handleResendCode}
          >
            Reenviar código
          </button>
        </div>
        <div className="form-row form--last">
          <Link to="/codigo-email" className="btn--simple">
            No tengo mi teléfono, enviar código a mi correo
          </Link>
        </div>
      </form>
    </div>
  );
};

export default CodePhoneComponent;
