import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
// import CausalesComponent from '../../../components/Auth/Causales/causales.component';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../types/userInterface';
import { strapiDoc } from '../../../types/strapiDocs';
import { updateUser } from '../../../api/usersApi';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { fetchStrapiDocs } from '../../../api/strapi';
import LoaderComponent from '../../../components/Loader/loader.component';
import DeleteAccountComponent from '../../../components/Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../../components/Modals/DeleteConfirmModal/deleteConfirmModal.component';
import CausalModalComponent from '../../../components/Modals/CausalModal/causalModal.component';
import { Accordion } from 'react-bootstrap';
import Markdown from 'react-markdown';
import CheckerBannerComponent from '../../../components/Form/CheckerBanner/checkerBanner.component';
import { useAlert } from '../../../hooks/useAlerts';

const CausalesScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate();
    const [currentStep] = useState(4);
    const totalSteps = 10;
    const [modalShowCausales, setModalShowCausales] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowConfirm, setModalShowConfirm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState('');
    const [user, setUser] = useState<Partial<IUser>>({});
    const [causales, setCausales] = useState<strapiDoc>();
    const {setError} = useAlert()
    const [causalData, setCausalData] = useState<
      {
        id: number;
        number: string;
        text: string;
        isChecked: boolean;
      }[]
    >([]);
  
    
  
    const handleDelete = () => {
      setModalShow(false);
      setModalShowConfirm(true);
    };
  
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      try {
        setModalShowCausales(true);
        const currentUser: Partial<IUser> = {
          ...user,
          datosCausales: {
            ...user.datosCausales,
            causales: causalData.map((c) => ({
              documentId: c.id,
              number: parseInt(c.number),
              description: c.text,
            })),
          },
        };
        setUser(currentUser);
        await updateUser(user._id || '', { ...currentUser }, token);
      } catch (error) {
        setError(`${error}`);
      } finally {
        setLoading(false);
      }
    };
  
    const handleModalSubmit = async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      signUpReason: string
    ) => {
      event.preventDefault();
      try {
        setModalShowCausales(false);
        setLoading(true);
        await updateUser(
          user._id || '',
          {
            ...user,
            datosCausales: {
              ...user.datosCausales,
              signUpReason,
            },
            step: 5,
          },
          token
        );
        navigate('/contexto-sociocultural');
      } catch (error) {
        setError(`${error}`);
      }
    };
  
    const handleCausalChange = (
      id: number,
      number: string | undefined,
      text: string,
      isChecked: boolean
    ) => {
      const currentData = [...causalData];
      if (isChecked) {
        currentData.push({ id, number: number || '', text, isChecked });
      } else {
        const index = currentData.findIndex((c) => c.id === id);
        currentData.splice(index, 1);
      }
      setCausalData(currentData);
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const currentUser = await getCurrentAuthenticatedUser();
          if (!currentUser) {
            navigate('/');
          }
          setToken(currentUser?.jwtToken || '');
          setUser(currentUser?.olaUser || {});
          const { causales } = currentUser?.olaUser.datosCausales || {};
          if (causales && causales.length > 0) {
            setCausalData(
              causales.map((c) => ({
                id: c.documentId,
                number: c.number.toString(),
                text: c.description,
                isChecked: true,
              }))
            );
            setModalShowCausales(true);
          }
          const query = {
            populate: {
              '0': 'causal',
              causal: {
                populate: '*',
              },
            },
          };
          const causalContent = await fetchStrapiDocs('causals', query);
          if (!causalContent.data || causalContent.data.length === 0) {
            throw new Error('No se encontraron causales');
          }
          setCausales(causalContent.data[0]);
        } catch (error) {
          setError(`${error}`);
        } finally {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setLoading(false);
        }
      };
      fetchData();
    }, []);
  

  return (
    <>
      <HeaderShared/>
      <section className="module container">
        {loading && <LoaderComponent />}
          <DeleteAccountComponent
            show={modalShow}
            onHide={() => setModalShow(false)}
            onDelete={handleDelete}
            setLoading={setLoading}
          />
          <DeleteConfirmComponent
            show={modalShowConfirm}
            onHide={() => setModalShowConfirm(false)}
          />
          <CausalModalComponent
            show={modalShowCausales}
            onHide={() => setModalShowCausales(false)}
            setLoading={setLoading}
            handleModalSubmit={handleModalSubmit}
          />
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
              <StepperComponent
                currentStep={currentStep}
                totalSteps={totalSteps}
              />
              </div>
              <div className="auth-row module-bottom row">
              <div className="col-md-5 col-12">
                {causales?.attributes.header && (
                  <h1>{causales.attributes.header}</h1>
                )}
                <article className="text-20 col-12 col-reset">
                  {causales?.attributes.subheader && (
                    <p className="text-green">
                      {causales.attributes.subheader}
                    </p>
                  )}
                  {causales?.attributes.content && (
                    <Markdown>{causales.attributes.content}</Markdown>
                  )}
                </article>
                <form onSubmit={handleSubmit}>
                  {causales?.attributes.causal?.length &&
                    causales.attributes.causal.map(
                      (
                        { id, Titulo, Descripcion, knowMoreLink, Listado },
                        i
                      ) => (
                        <div key={i} className="form-row">
                          <div
                            className={`causal-card ${
                              i > 0 ? `card--color${i}` : ''
                            }`}
                          >
                            <h3 className="text-regular">
                              {`Causal ${i + 1}`}
                            </h3>
                            <article>
                              {Titulo && (
                                <Markdown
                                  className={`text-20 ${
                                    i > 1 ? 'text-purple300' : 'text-purple800'
                                  } text-700`}
                                >
                                  {Titulo}
                                </Markdown>
                              )}
                              {Descripcion && (
                                <Markdown>{Descripcion}</Markdown>
                              )}
                              {knowMoreLink && (
                                <div className="mt-4 text-purple500 text-700 col-12">
                                  <p className="mb-0 text-end">
                                    <em>Quiero saber más</em>
                                  </p>
                                </div>
                              )}
                            </article>
                            {Listado && Listado.length > 1 ? (
                              <Accordion key={i} className="card-accordeon">
                                <Accordion.Item eventKey={id.toString()}>
                                  <Accordion.Header>
                                    Listado de patologías
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {Listado.map(({ id, Nombre }, j) => {
                                      return (
                                        <CheckerBannerComponent
                                          key={id}
                                          id={id}
                                          onChange={handleCausalChange}
                                          number={`${j + 1}`}
                                          text={
                                            <Markdown
                                              allowedElements={[
                                                'span',
                                                'strong',
                                              ]}
                                              unwrapDisallowed={true}
                                            >
                                              {Nombre}
                                            </Markdown>
                                          }
                                          value={Nombre}
                                          checked={
                                            causalData.findIndex(
                                              (c) => c.id === id
                                            ) !== -1
                                          }
                                        />
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            ) : (
                              Listado &&
                              Listado.length > 0 && (
                                <CheckerBannerComponent
                                  id={Listado[0].id}
                                  onChange={handleCausalChange}
                                  number="1"
                                  text={<span>{Listado[0].Nombre}</span>}
                                  value={Listado[0].Nombre}
                                />
                              )
                            )}
                          </div>
                          <hr />
                        </div>
                      )
                    )}
                  <div className="module-top40 form-row form--last">
                    <button
                      type="submit"
                      className="btn btn--type1"
                      disabled={causalData.findIndex((c) => c.isChecked) === -1}
                    >
                      Ya seleccioné en qué causal o causales estoy
                    </button>
                  </div>
                  <div className="form-row form--last">
                    <a href="#" className="btn btn--type2">
                      No estoy en ninguna causal
                    </a>
                  </div>
                  <div className="form-row form--last">
                    <button
                      type="button"
                      className="btn--danger"
                      onClick={() => setModalShow(true)}
                    >
                      Borrar mi registro
                    </button>
                  </div>
                </form>
              </div>
            </div>
              <aside className="hide-tablet col-lg-6 col-12 d-flex justify-content-end">
                <figure className="image-270">
                  <img src="/assets/images/illustration/computer.png" alt="Te acompañamos" />
                </figure>
              </aside>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default CausalesScreen;