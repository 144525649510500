import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { IUser } from '../types/userInterface';

interface HeaderSharedProps {
  user?: Partial<IUser>;
}

const HeaderShared: React.FC<HeaderSharedProps> = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLogged, setIsLogged] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isNotificationOn, setNotificationOn] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Cambia el estado al opuesto de lo que está actualmente
  };
  const location = useLocation();

  const isActive = (path: string): string => {
    return location.pathname === path ? 'active' : '';
  };

  useEffect(() => {
    if (user) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [user]);

  return (
    <header>
      <div className="container">
        <div className="header__col col-12">
          <a href="/" className="header__logo">
            <figure>
              <img
                src={`${
                  isOpen
                    ? '/assets/images/logo/salud-logo-white.png'
                    : '/assets/images/logo/salud-logo.png'
                }`}
                alt="Red de Salud Comunitaria"
              />
            </figure>
          </a>
          <nav className={`main-nav ${isOpen ? 'active' : ''}`}>
            <ul>
              {isLogged ? (
                <li className="main-nav__mobile">
                  <div className="header-options__mobile">
                    <div className="header-options__top">
                      {isNotificationOn ? (
                        <a href="/" className="notifications">
                          <i className="icon icon--notification-on-white"></i>
                        </a>
                      ) : (
                        ''
                      )}
                      <a href="/" className="user-control-button">
                        <i className="icon icon--user-small"></i>
                      </a>
                    </div>
                    {isNotificationOn ? (
                      <div className="header-alert justify-content-center">
                        <span className="icon material-icons-outlined">
                          report_problem
                        </span>
                        <p>Alguien más accedió a mi perfil</p>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </li>
              ) : (
                <>
                  <li>
                    <a href="/" className={isActive('/')}>
                      Iniciar sesión
                    </a>
                  </li>
                  <li>
                    <a
                      href="/crear-cuenta"
                      className={isActive('/crear-cuenta')}
                    >
                      Regístrate
                    </a>
                  </li>
                  <li>
                    <a href="/donativo" className={isActive('/donativo')}>
                      Dona a Las Amigas
                    </a>
                  </li>
                </>
              )}
              {isLogged ? (
                <>
                  <li className="main-nav__mobile">
                    <a href="/" className={isActive('/dona')}>
                      Ver proceso
                    </a>
                  </li>
                  <li className="main-nav__mobile">
                    <a
                      href="/"
                      className={isActive('/dona')}
                      onClick={() => signOut().then(() => navigate('/'))}
                    >
                      Cerrar sesión
                    </a>
                  </li>
                </>
              ) : (
                ''
              )}
            </ul>
          </nav>
          {isLogged ? (
            <div className="header__options">
              {isNotificationOn ? (
                <>
                  <div className="header-alert">
                    <span className="icon material-icons-outlined">
                      report_problem
                    </span>
                    <p>Alguien más accedió a mi perfil</p>
                  </div>
                  <a href="/" className="notifications">
                    <i className="icon icon--notification-on"></i>
                  </a>
                </>
              ) : (
                ''
              )}
              <div className="user-control">
                <button type="button" className="user-control-button">
                  <i className="icon icon--user-small"></i>
                </button>
                <div className="user-options">
                  <div className="user-options__top">
                    <i className="icon icon--user-big"></i>
                    <article>
                      <h3 className="text-regular text-20">
                        {user?.username || ''}
                      </h3>
                      <a href="/">Ver perfil</a>
                    </article>
                  </div>
                  <div className="user-options__bottom">
                    <ul>
                      <li>
                        <a href="/mi-proceso">
                          <i className="icon icon--search"></i>
                          <span>Ver proceso</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          onClick={() => signOut().then(() => navigate('/'))}
                        >
                          <i className="icon icon--logout"></i>
                          <span>Cerrar sesión</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <button
            type="button"
            className={`open-nav ${isOpen ? 'active' : ''}`}
            onClick={handleButtonClick}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderShared;
