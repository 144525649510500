import {
  fetchAuthSession,
  fetchUserAttributes,
  signOut,
} from 'aws-amplify/auth';
import { uploadData } from 'aws-amplify/storage';
import { getUser } from '../api/usersApi';
import { UserStatus } from '../types/userInterface';
import Swal from 'sweetalert2';

// Función de ayuda para la fortaleza de la contraseña
const getPasswordStrength = (password: string) => {
  let strength = 0;
  if (password.length >= 8 && password.length <= 15) strength++;
  if (/\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password))
    strength++;
  if (/[A-Z]/.test(password)) strength++;
  return strength; // 0 = débil, 1 = aceptable, 2 = fuerte
};

const getCurrentAuthenticatedUser = async () => {
  try {
    const authSession = await fetchAuthSession();
    const userAttributes = await fetchUserAttributes();

    if (authSession && userAttributes) {
      const key = 'custom:olaUserId';
      const olaUserId = userAttributes[key] || '';
      const jwtToken = authSession.tokens?.idToken?.toString() || '';
      const olaUser = await getUser(olaUserId, jwtToken);
      const currentStatus = olaUser?.status || '';
      if (currentStatus !== UserStatus.ACTIVE) {
        await signOut();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Tu cuenta ha sido desactivada. Por favor, ponte en contacto con el equipo de soporte.',
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/'; // Redirige a la página de inicio
          }
        });
      }
      return { olaUser, jwtToken };
    }
  } catch (error) {
    return null;
  }
};

const handleUploadFile = async (userId: string, file: File, folder: string) => {
  try {
    const task = await uploadData({
      key: `${userId}/${folder}/${file.name}`,
      data: file,
      options: {
        contentType: file.type,
        accessLevel: 'guest',
      },
    });
    const savedFileResponse = await task.result;
    return savedFileResponse.key;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export { getPasswordStrength, getCurrentAuthenticatedUser, handleUploadFile };
