import axios from 'axios';
import { IUserChatPaginate } from '../types/userInterface';

const chatApi = axios.create({
  baseURL: 'https://olachat.manifesto.design/',
  headers: {
    'Content-Type': 'application/json',
  },
});

const fetchChatMessages = async (roomId: string, token: string) => {
  try {
    const response = await chatApi.get<IUserChatPaginate>('/messages', {
      params: { roomId },
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};

export { fetchChatMessages };
